import { useState } from 'preact/hooks';
import { isNil, Nilable } from '@wistia/type-guards';
import { FormApi } from '../utilities/FormApi.ts';

type UseRegistrantRestrictedProps = {
  embedHost?: Nilable<string>;
  liveEventId: string;
  registrantUid: string;
};

export const useRegistrantRestricted = ({
  registrantUid,
  liveEventId,
  embedHost,
}: UseRegistrantRestrictedProps): boolean => {
  const [isRegistrationRestricted, setIsRegistrationRestricted] = useState<boolean>(true);

  const fetchIsRegistrationRestricted = async () => {
    if (!registrantUid || !liveEventId) {
      return false;
    }
    const formApi = new FormApi(embedHost);

    const response = await formApi.getRegistrantRestricted(registrantUid, liveEventId);

    const { is_registrant_restricted: isRegistrantRestricted } = response;

    return isRegistrantRestricted;
  };

  void fetchIsRegistrationRestricted().then((response) => setIsRegistrationRestricted(response));

  if (isNil(fetchIsRegistrationRestricted)) {
    setIsRegistrationRestricted(false);
  }

  return isRegistrationRestricted;
};
