import { useCallback, useEffect, useState } from 'preact/hooks';
import { isNil, Nilable } from '@wistia/type-guards';
import { CalendarLinks, FormApi } from '../utilities/FormApi.ts';

type UseCalendarLinksProps = {
  embedHost?: Nilable<string>;
  liveEventId: string;
  registrationUid: Nilable<string>;
};

export const useCalendarLinks = ({
  liveEventId,
  registrationUid,
  embedHost,
}: UseCalendarLinksProps): CalendarLinks => {
  const [calendarLinksResponse, setCalendarLinksResponse] = useState<CalendarLinks | null>(null);

  const fetchCalendarLinks = useCallback(async () => {
    const formApi = new FormApi(embedHost);

    const response = await formApi.getCalendarLinks(liveEventId, registrationUid);

    setCalendarLinksResponse(response);
  }, [liveEventId, registrationUid]);

  useEffect(() => {
    void fetchCalendarLinks();
  }, [fetchCalendarLinks]);

  if (isNil(calendarLinksResponse)) {
    return {
      google: null,
      ics: null,
    };
  }

  return calendarLinksResponse;
};
