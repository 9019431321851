import { isNonEmptyString, Nilable } from '@wistia/type-guards';
import { CustomField, Field, FormFieldConfig } from '../components/FormField.tsx';
import { LiveEventLifecycleStatus } from '../types.ts';
import { appHostname } from '../../../appHostname.js';
import { cdnFastWistiaNetHost } from '../../../utilities/hosts.js';

const FAST_HOSTNAME: string = cdnFastWistiaNetHost();

type PostRegistrantResult = {
  error?: string;
  registration_uid?: string;
  status: number;
  success: boolean;
};

export type FormFieldData = {
  id: number;
  value: string;
};

export type FormFieldLeadResponse = {
  form_field_id: number;
  value: string;
};

export type BaseFormSubmissionData = {
  company?: string;
  country?: string;
  email: string;
  first_name: string;
  job_title?: string;
  last_name: string;
  live_event_id: string;
  phone_number?: string;
};

export type FormSubmissionData = BaseFormSubmissionData & Record<string, string>;

export type FormSubmissionPayload = BaseFormSubmissionData & {
  form_field_lead_responses?: FormFieldLeadResponse[];
};

export type LiveTrackerRegistration = BaseFormSubmissionData & {
  form_field_data?: FormFieldData[];
};

export type LiveFormCustomizations = {
  border_radius?: number;
  button_border_radius?: number;
  button_color?: string;
  button_font?: string;
  button_font_url?: string;
  container_border_radius?: number;
  date_enabled: boolean;
  date_time_background_color?: string;
  date_time_text_color?: string;
  error_border_color?: string;
  error_text_color?: string;
  form_background_color?: string;
  has_powered_by_wistia_badge: boolean;
  heading_text_color?: string;
  input_background_color?: string;
  input_border_color?: string;
  input_border_radius?: number;
  input_font?: string;
  input_font_url?: string;
  input_label_font?: string;
  input_label_font_url?: string;
  input_text_color?: string;
  main_text_color?: string;
  primary_button_background_color?: string;
  primary_button_border_color?: string;
  primary_button_hover_background_color?: string;
  primary_button_hover_text_color?: string;
  primary_button_text_color?: string;
  primary_color?: string;
  primary_text_color?: string;
  required_indicator_color?: string;
  secondary_font_size?: number;
  time_enabled: boolean;
  title: string;
  title_enabled: boolean;
  title_font?: string;
  title_font_size?: number;
  title_font_url?: string;
};

export type LiveEventConfig = {
  account_id: number;
  analytics_api: string;
  analytics_host: string;
  anonymize_ips_for_analytics: boolean;
  customizations: LiveFormCustomizations;
  event_url: string;
  forms_api: string;
  forms_host: string;
  has_registration_form: boolean;
  is_event_joinable: boolean;
  is_event_over: boolean;
  is_live_for_analytics: boolean;
  lifecycle_status: LiveEventLifecycleStatus;
  live_stream_event_id: number;
  media_id: number;
  on_demand_enabled: boolean;
  ready_to_attend: boolean;
  scheduled_for: string;
};

export type FormEmbedData = {
  form: FormConfig;
  live_event: LiveEventConfig;
};

export type FormEmbedServerData = {
  form: {
    fields: Field[];
  };
  live_event: LiveEventConfig;
};

export type FormConfig = {
  customFields: CustomField[];
  standardFields: FormFieldConfig[];
};

export type CalendarLinks = {
  google: Nilable<string>;
  ics: Nilable<string>;
};

type RestrictedRegistration = {
  is_registrant_restricted: boolean;
};

export class FormApi {
  private readonly embedHost: string;

  public constructor(embedHost?: Nilable<string>) {
    this.embedHost = embedHost ?? FAST_HOSTNAME;
  }

  public async get(liveEventId: string): Promise<FormEmbedServerData> {
    const response = await fetch(`https://${this.embedHost}/embed/forms/${liveEventId}`);

    if (!response.ok) {
      const error = await response.text();
      return Promise.reject(new Error(error));
    }

    return (await response.json()) as FormEmbedServerData;
  }

  public async getCalendarLinks(
    liveEventId: string,
    registrationUid: Nilable<string>,
  ): Promise<CalendarLinks> {
    let url = `https://${this.embedHost}/embed/forms/${liveEventId}/calendar_links`;

    if (isNonEmptyString(registrationUid)) {
      url += `?ruid=${registrationUid}`;
    }

    const response = await fetch(url);

    return (await response.json()) as CalendarLinks;
  }

  public async getRegistrantRestricted(
    registrationUid: string,
    liveEventId: string,
  ): Promise<RestrictedRegistration> {
    if (!registrationUid || !liveEventId) {
      throw new Error('registrationUid and liveEventId are required');
    }
    const response = await fetch(
      `https://${this.embedHost}/live_stream_event/${liveEventId}/registration/${registrationUid}`,
    );

    return (await response.json()) as RestrictedRegistration;
  }

  public async submit(data: FormSubmissionPayload): Promise<PostRegistrantResult> {
    // We make this POST straight to the VMA app, not through Fastly, because
    // our Fastly VCL is configured to cache OPTIONS requests, and in doing so
    // it caches the referrer. If a person were to attempt to register for
    // Wistia live events across multiple domains (maybe just within 24 hours?),
    // the OPTIONS response would be cached with the Access-Control-Allow-Origin
    // header set to the first domain they registered on. This would cause the
    // second POST request to fail with a CORS error.
    const response = await fetch(`https://${appHostname('app')}/live_event_registration`, {
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const responseJson = (await response.json()) as PostRegistrantResult;

    if (!response.ok) {
      const error = responseJson.error ?? 'Unknown error';
      return Promise.reject(new Error(error));
    }

    return responseJson;
  }
}
