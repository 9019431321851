import { Fragment, h, JSX } from 'preact';
import { isNotNil } from '@wistia/type-guards';
import { useEffect } from 'preact/hooks';
import { FormButton } from '../FormButton.tsx';
import { useFormState } from '../../context/FormStateContext.tsx';
import {
  eventUrlWithRegistrantQueryParams,
  getLocalDateAndTime,
  openUrlInNewTab,
} from '../../utilities/eventHelpers.ts';
import { getRegistrationByEventId } from '../../utilities/localStorageHelpers.ts';
import { CalendarLinks } from '../CalendarLinks.tsx';
import { countMetric } from '../../../../utilities/simpleMetrics.js';
import { useRegistrantRestricted } from '../../hooks/useRegistrantRestricted.ts';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const THIRTY_MINUTES = 30 * 60 * 1000;
const INTERVAL_MS = 3000;

export const RegisteredFormContent = (): JSX.Element => {
  const { liveEventId, liveEventConfig, refreshData } = useFormState();
  const {
    scheduled_for: scheduledFor,
    has_registration_form: hasRegistrationForm,
    is_event_joinable: isEventJoinable,
    is_event_over: isEventOver,
  } = liveEventConfig;

  const registration = getRegistrationByEventId(liveEventId);
  const { id: registrationUid } = registration ?? { id: '' };

  useEffect(() => {
    if (isEventJoinable || isEventOver) {
      return () => null;
    }

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const eventTime = new Date(scheduledFor).getTime();
      const isEventInLessThanThirtyMinutes = eventTime - currentTime < THIRTY_MINUTES;

      if (!isEventInLessThanThirtyMinutes) {
        return;
      }

      refreshData();
      clearInterval(interval);
    }, INTERVAL_MS);

    return () => clearInterval(interval);
  }, [isEventJoinable, isEventOver]);

  const handleGoToEventClick = (event: h.JSX.TargetedMouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    countMetric('live_reg_form/go_to_event', 1, { liveEventId, registrationUid });

    const url =
      hasRegistrationForm && isNotNil(registrationUid)
        ? eventUrlWithRegistrantQueryParams(liveEventConfig.event_url, registrationUid)
        : liveEventConfig.event_url;

    openUrlInNewTab(url);
  };

  const isRegistrationRestricted = useRegistrantRestricted({
    registrantUid: registrationUid,
    liveEventId,
  });

  // event started
  if (isEventJoinable) {
    return (
      <div class="event-joinable-content" part="event-joinable-content non-form-content">
        <p class="loud">This event is happening now, go take a look!</p>
        <FormButton text="Go To Event" onClick={handleGoToEventClick} id="w-navigate-to-event" />
      </div>
    );
  }

  // event is over and vod is ready
  if (isEventOver && liveEventConfig.lifecycle_status === 'vod_ready') {
    return (
      <div class="vod-ready-content" part="vod-ready-content non-form-content">
        <p class="loud">Sorry you missed us, but checkout the recording!</p>
        <FormButton
          text="Go To Recording"
          onClick={handleGoToEventClick}
          id="w-navigate-to-event"
        />
      </div>
    );
  }

  if (isEventOver) {
    return (
      <div class="vod-not-ready-content" part="vod-not-ready-content non-form-content">
        <p class="loud">Sorry you missed us, but we'll have a recording ready soon!</p>
      </div>
    );
  }

  // event not started
  const { date, time } = getLocalDateAndTime(liveEventConfig.scheduled_for);

  const part = hasRegistrationForm ? 'post-registration-content' : 'registration-disabled-content';

  return (
    <div
      class="post-registration-content"
      part={`${part} non-form-content`}
      style={{ paddingBottom: isRegistrationRestricted ? '13.5rem' : 'auto' }}
    >
      {hasRegistrationForm && (
        <Fragment>
          <p class="loud">Congrats!</p>
          <p class="loud">You've registered for this event.</p>
        </Fragment>
      )}
      {!hasRegistrationForm && <p class="loud">Thanks for your interest!</p>}
      {!isRegistrationRestricted ? <p>{`Come back at ${time} on ${date}.`}</p> : null}
      {!isRegistrationRestricted && <CalendarLinks registrationUid={registrationUid} />}
    </div>
  );
};
