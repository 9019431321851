import { h, JSX } from 'preact';
import { Nilable } from '@wistia/type-guards';
import { CalendarLink } from './CalendarLink.tsx';
import { useFormState } from '../context/FormStateContext.tsx';
import { useCalendarLinks } from '../hooks/useCalendarLinks.ts';
import { countMetric } from '../../../utilities/simpleMetrics.js';

export const CALENDAR_LINKS_STYLES = `
  .calendar-links-container {
    padding-top: var(--spacing-4);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  }
  
  .calendar-links-container p {
    font-size: var(--font-size-2);
    line-height: var(--spacing-4);
  }

  .calendar-links {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-2);
    flex-wrap: wrap;
    width: 100%;
  }
`;

type CalendarLinksProps = {
  registrationUid: Nilable<string>;
};

export type CalendarLinkType = 'google' | 'ical' | 'outlook';

export const CalendarLinks = ({ registrationUid }: CalendarLinksProps): JSX.Element | null => {
  const { liveEventId, embedHost } = useFormState();

  const { google, ics } = useCalendarLinks({
    liveEventId,
    registrationUid,
    embedHost,
  });

  const onClick = (calendarType: CalendarLinkType): void => {
    countMetric('live_reg_form/added_to_calendar', 1, {
      liveEventId,
      calendarType,
      registrationUid,
    });
  };

  return (
    <div class="calendar-links-container">
      <p>Add this event to your calendar.</p>
      <div class="calendar-links">
        <CalendarLink calendarLink={google} calendarType="google" onClick={onClick} />
        <CalendarLink calendarLink={ics} calendarType="ical" onClick={onClick} />
        <CalendarLink calendarLink={ics} calendarType={'outlook'} onClick={onClick} />
      </div>
    </div>
  );
};
