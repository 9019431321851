import { h, JSX } from 'preact';
import { Color } from '../../../utilities/color.js';
import { WistiaLogo } from './Icons/WistiaLogo.tsx';
import { useFormState } from '../context/FormStateContext.tsx';

export const POWERED_BY_WISTIA_BADGE_STYLES = `
.powered-by-wistia-badge {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-decoration: none;

  color: var(--form-text-color);
}

`;

export const PoweredByWistiaBadge = (): JSX.Element => {
  const {
    liveEventId,
    liveEventConfig: { customizations },
  } = useFormState();

  // First try to access the background color from the customizations
  let formBackgroundColor = new Color(customizations.form_background_color);

  const formElement = document.querySelector(`wistia-form[live-event-id="${liveEventId}"]`);

  // There shouldn't be any instances when formElement doesn't exist, but as a precaution we'll do a check here
  if (formElement) {
    // Since there could be custom CSS on the page, try to access the form background color from the CSS variable
    const cssVarColor = getComputedStyle(formElement).getPropertyValue('--form-background-color');
    if (cssVarColor) {
      formBackgroundColor = new Color(cssVarColor);
    }
  }

  const hasDarkFormBackground = !formBackgroundColor.isLight();
  const href = `https://www.wistia.com?utm_type=live-event&utm_campaign=wistia-branding&utm_medium=referral&utm_source=wistia&about=${liveEventId}`;

  return (
    <a className="powered-by-wistia-badge" href={href} target="_blank">
      Powered by <WistiaLogo isDarkVariant={hasDarkFormBackground} title="Wistia" />
    </a>
  );
};
