/**
 * Function that creates a url with query params for the registrant data.
 * @param eventUrl
 * @param registrationUid
 * @returns {string}
 */
export const eventUrlWithRegistrantQueryParams = (
  eventUrl: string,
  registrationUid: string,
): string => {
  const queryParams = new URLSearchParams({
    ruid: registrationUid,
  });

  const url = new URL(eventUrl);

  url.search = queryParams.toString();

  return url.href;
};

export const openUrlInNewTab = (url: string): void => {
  window.open(url, '_blank');
};

export const getLocalDateAndTime = (scheduledFor: string): { date: string; time: string } => {
  const date = new Date(scheduledFor).toLocaleDateString();
  const time = new Date(scheduledFor).toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });

  return { date, time };
};
