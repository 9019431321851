import { isNotNil, isNonEmptyArray } from '@wistia/type-guards';
import { getVisitorKey } from '../utilities/localStorageHelpers.ts';
import { isFirstEvent, sendAnalyticsEvent } from './helpers.ts';
import {
  AnalyticsData,
  Event,
  FormAnalyticsData,
  FormLoadAnalyticsEvent,
  FormLoadedAnalyticsEvent,
  FormRegisteredAnalyticsEvent,
  FormScope,
  FormSubmissionAnalyticsEvent,
  LiveEventFormAnalyticsContext,
  LoadEvent,
  Scope,
  SubmissionEvent,
} from './types.ts';
import { FormFieldData, LiveTrackerRegistration } from '../utilities/FormApi.ts';

// TODO: Delete after switch to form API
type FormLoadedAnalyticsProps = {
  event?: Event;
  isFirst: boolean;
  isLive: boolean;
  scope?: Scope;
  time?: number;
};

type FormLoadAnalyticsProps = {
  event?: LoadEvent;
  isFirst: boolean;
  isLive: boolean;
  scope?: FormScope;
  time?: number;
};

// TODO: Delete after switch to form API
type FormRegisteredAnalyticsProps = {
  company?: string;
  country?: string;
  email: string;
  event: Event;
  first_name: string;
  form_field_data?: FormFieldData[];
  isFirst: boolean;
  isLive: boolean;
  job_title?: string;
  last_name: string;
  phone?: string;
  scope: Scope;
  time: number;
};

type FormSubmissionAnalyticsProps = {
  company?: string;
  country?: string;
  email: string;
  event: SubmissionEvent;
  first_name: string;
  form_field_data?: FormFieldData[];
  isFirst: boolean;
  isLive: boolean;
  job_title?: string;
  last_name: string;
  phone_number?: string;
  scope: FormScope;
  time: number;
};

// TODO: Delete after switch to form API
const buildLoadedEvent = ({
  time = Date.now(),
  event = 'load',
  scope = 'registration',
  isFirst = true,
  isLive = true,
}: FormLoadedAnalyticsProps): FormLoadedAnalyticsEvent => ({
  time,
  event,
  scope,
  first: isFirst,
  live: isLive,
});

const buildLoadEvent = ({
  time = Date.now(),
  event = 'load',
  scope = 'form',
  isFirst = true,
  isLive = true,
}: FormLoadAnalyticsProps): FormLoadAnalyticsEvent => ({
  time,
  event,
  scope,
  first: isFirst,
  live: isLive,
});

// TODO: Delete after switch to form API
const buildRegisteredEvent = ({
  time = Date.now(),
  event = 'register',
  scope = 'registration',
  isFirst = true,
  isLive = true,
  first_name,
  last_name,
  email,
  company,
  job_title,
  phone,
  country,
  form_field_data,
}: FormRegisteredAnalyticsProps): FormRegisteredAnalyticsEvent => {
  const registeredEvent: FormRegisteredAnalyticsEvent = {
    time,
    event,
    scope,
    live: isLive,
    first: isFirst,
    first_name,
    last_name,
    email,
  };

  if (isNotNil(company)) {
    registeredEvent.company = company;
  }

  if (isNotNil(job_title)) {
    registeredEvent.job_title = job_title;
  }

  if (isNotNil(phone)) {
    registeredEvent.phone = phone;
  }

  if (isNotNil(country)) {
    registeredEvent.country = country;
  }

  if (isNonEmptyArray(form_field_data)) {
    registeredEvent.form_field_data = form_field_data;
  }

  return registeredEvent;
};

const buildFormSubmissionEvent = ({
  time = Date.now(),
  event = 'submission',
  scope = 'form',
  isFirst = true,
  isLive = true,
  first_name,
  last_name,
  email,
  company,
  job_title,
  phone_number,
  country,
  form_field_data,
}: FormSubmissionAnalyticsProps): FormSubmissionAnalyticsEvent => {
  const registeredEvent: FormSubmissionAnalyticsEvent = {
    time,
    event,
    scope,
    live: isLive,
    first: isFirst,
    first_name,
    last_name,
    email,
  };

  if (isNotNil(company)) {
    registeredEvent.company = company;
  }

  if (isNotNil(job_title)) {
    registeredEvent.job_title = job_title;
  }

  if (isNotNil(phone_number)) {
    registeredEvent.phone_number = phone_number;
  }

  if (isNotNil(country)) {
    registeredEvent.country = country;
  }

  registeredEvent.form_field_data = form_field_data ?? [];

  return registeredEvent;
};

// TODO: Delete after switch to form API
const buildAnalyticsData = (
  analyticsContext: LiveEventFormAnalyticsContext,
  analyticsEvent: FormLoadedAnalyticsEvent | FormRegisteredAnalyticsEvent,
): AnalyticsData => {
  const {
    sessionKey: session_key,
    accountId: account_id,
    mediaId: media_id,
    shouldAnonymizeIp: anonymize_ip,
    liveStreamEventId: live_stream_event_id,
  } = analyticsContext;

  return {
    account_id,
    embed_url: window.location.href,
    events: [analyticsEvent],
    live_stream_event_id,
    media_id,
    media_type: 'Video',
    player_type: 'liveRegistrationFormEmbed',
    referrer_url: document.referrer,
    session_key,
    anonymize_ip,
    visitor_key: getVisitorKey(),
  };
};

const buildFormAnalyticsData = (
  formHashedId: string,
  analyticsContext: LiveEventFormAnalyticsContext,
  analyticsEvent: FormLoadAnalyticsEvent | FormSubmissionAnalyticsEvent,
): FormAnalyticsData => {
  const {
    sessionKey: session_key,
    accountId: account_id,
    mediaId: media_id,
    shouldAnonymizeIp: anonymize_ip,
    liveStreamEventId: live_stream_event_id,
  } = analyticsContext;
  return {
    account_id,
    embed_url: window.location.href,
    events: [analyticsEvent],
    parent_embed_type: 'LiveStreamEvent',
    parent_embed_id: live_stream_event_id,
    form_embed_hashed_id: formHashedId,
    media_id,
    media_type: 'Video',
    player_type: 'liveRegistrationFormEmbed',
    referrer_url: document.referrer,
    session_key,
    anonymize_ip,
    visitor_key: getVisitorKey(),
  };
};

export const FormAnalyticsApi = {
  trackFormLoaded: async (
    formHashedId: string,
    analyticsContext: LiveEventFormAnalyticsContext,
  ): Promise<void> => {
    const { analyticsHost, analyticsAPI, isLiveForAnalytics, liveStreamEventId } = analyticsContext;

    if (analyticsAPI === '/form-stream/1.0.0') {
      const analyticsEvent = buildLoadEvent({
        isLive: isLiveForAnalytics,
        isFirst: isFirstEvent({
          liveStreamEventId,
          eventType: 'load',
        }),
      });

      await sendAnalyticsEvent(
        analyticsHost,
        analyticsAPI,
        buildFormAnalyticsData(formHashedId, analyticsContext, analyticsEvent),
      );
    } else {
      // TODO: Delete after switch to form API
      const analyticsEvent = buildLoadedEvent({
        isLive: isLiveForAnalytics,
        isFirst: isFirstEvent({
          liveStreamEventId,
          eventType: 'load',
        }),
      });

      await sendAnalyticsEvent(
        analyticsHost,
        analyticsAPI,
        buildAnalyticsData(analyticsContext, analyticsEvent),
      );
    }
  },
  trackFormSubmitted: async (
    formHashedId: string,
    analyticsContext: LiveEventFormAnalyticsContext,
    registration: LiveTrackerRegistration,
  ): Promise<void> => {
    const {
      liveStreamEventId,
      analyticsHost,
      analyticsAPI,
      formsHost,
      formsAPI,
      isLiveForAnalytics,
    } = analyticsContext;

    const {
      first_name,
      last_name,
      email,
      company,
      country,
      job_title,
      phone_number,
      form_field_data,
    } = registration;

    if ((formsAPI || analyticsAPI) === '/form-submissions/1.0.0') {
      const analyticsEvent = buildFormSubmissionEvent({
        company,
        country,
        email,
        event: 'submission',
        first_name,
        form_field_data,
        isFirst: isFirstEvent({
          liveStreamEventId,
          eventType: 'submission',
        }),
        isLive: isLiveForAnalytics,
        job_title,
        last_name,
        phone_number,
        scope: 'form',
        time: Date.now(),
      });

      await sendAnalyticsEvent(
        formsHost || analyticsHost,
        formsAPI || analyticsAPI,
        buildFormAnalyticsData(formHashedId, analyticsContext, analyticsEvent),
      );
    } else {
      // TODO: Delete after switch to form API
      const analyticsEvent = buildRegisteredEvent({
        company,
        country,
        email,
        event: 'register',
        first_name,
        form_field_data,
        isFirst: isFirstEvent({
          liveStreamEventId,
          eventType: 'register',
        }),
        isLive: isLiveForAnalytics,
        job_title,
        last_name,
        phone: phone_number,
        scope: 'registration',
        time: Date.now(),
      });

      await sendAnalyticsEvent(
        formsHost || analyticsHost,
        formsAPI || analyticsAPI,
        buildAnalyticsData(analyticsContext, analyticsEvent),
      );
    }
  },
};
