import { h, JSX } from 'preact';
import { interFontFamily } from '../../../utilities/interFontFamily.js';
import { FORM_HEADER_STYLES } from './FormStates/FormHeader.tsx';
import { UNREGISTERED_FORM_STYLES } from './FormStates/UnregisteredFormContent.tsx';
import { FORM_FIELD_STYLES } from './FormField.tsx';
import { FORM_INPUT_STYLES } from './FormInput.tsx';
import { FORM_SELECT_STYLES } from './FormSelect.tsx';
import { FORM_BUTTON_STYLES } from './FormButton.tsx';
import { DISCLAIMER_STYLES } from './Disclaimer.tsx';
import { CALENDAR_LINK_STYLES } from './CalendarLink.tsx';
import { CALENDAR_LINKS_STYLES } from './CalendarLinks.tsx';
import {
  FORM_STYLES,
  NON_REGISTRATION_FORM_CONTENT_STYLES,
} from './FormStates/FormStateRouter.tsx';
import { FormEmbedData } from '../utilities/FormApi.ts';
import {
  appendUnit,
  getBorderRadius,
  getFontFamilyArrayFromData,
} from '../utilities/styleHelpers.ts';
import { useCustomAndGoogleFonts } from '../../shared/hooks/useGoogleAndCustomFonts.ts';
import { POWERED_BY_WISTIA_BADGE_STYLES } from './PoweredByWistiaBadge.tsx';

export const FormStyles = ({ data }: { data: FormEmbedData }): JSX.Element => {
  const { live_event: liveEventConfig } = data;
  const { customizations } = liveEventConfig;

  useCustomAndGoogleFonts(getFontFamilyArrayFromData(liveEventConfig.customizations));

  return (
    <style>
      {`
      :host {
        --grey-000: #ffffff;
        --grey-100: #f8f8f9;
        --grey-200: #ebebed;
        --grey-300: #c4c4c8;
        --grey-600: #7b7b87;
        --grey-900: #37373c;
        --grey-1100: #0b0d0e;
        --blue-500: #1e64f0;
        --blue-600: #174bd2;
        --black: #000000;

        --spacing-1: 4px;
        --spacing-2: 8px;
        --spacing-3: 12px;
        --spacing-4: 16px;
        --spacing-5: 20px;
        --spacing-6: 24px;

        --font-family: ${interFontFamily};

        --font-size-1: 12px;
        --font-size-2: 14px;
        --font-size-3: 16px;
        --font-size-4: 18px;
        --font-size-5: 20px;
        --font-size-6: 24px;

        --font-weight-1: 400;
        --font-weight-2: 600;

        --border-radius-1: 4px;

        --button-font-family: ${customizations.button_font ?? 'var(--font-family)'};
        --input-font-family: ${customizations.input_font ?? 'var(--font-family)'};
        --input-label-font-family: ${customizations.input_label_font ?? 'var(--font-family)'};
        --title-font-family: ${customizations.title_font ?? 'var(--font-family)'};
        --title-font-size: ${
          appendUnit(customizations.title_font_size, 'rem') ?? 'var(--font-size-6)'
        };
        
        --form-text-color: ${customizations.main_text_color ?? 'var(--black)'};
        --form-heading-color: ${customizations.heading_text_color ?? 'var( --black)'};
        
        --form-background-color: ${customizations.form_background_color ?? 'var(--grey-000)'};
        --input-background-color: ${customizations.form_background_color ?? 'var(--grey-000)'};
        --input-text-color: ${customizations.main_text_color ?? 'var(--grey-900)'};
        --input-border-color: ${customizations.input_border_color ?? 'var(--grey-300)'};
        --input-requiredness-text-color: ${
          customizations.required_indicator_color ?? 'var(--grey-600)'
        };
        
        --button-background-color: ${customizations.button_color ?? 'var(--blue-500)'};
        --button-hover-background-color: ${
          customizations.primary_button_hover_background_color ?? 'var(--blue-600)'
        };
        --button-text-color: ${customizations.primary_button_text_color ?? 'var(--grey-000)'};
        --button-hover-text-color: ${
          customizations.primary_button_hover_text_color ?? 'var(--grey-000)'
        };
        --button-border-color: ${customizations.primary_button_border_color ?? 'var(--blue-500)'};
        
        --date-time-background-color: ${
          customizations.date_time_background_color ?? 'var(--grey-100)'
        };

        --form-border-radius: ${
          getBorderRadius(customizations, 'container_border_radius') ?? 'var(--border-radius-1)'
        };
        --input-border-radius: ${
          getBorderRadius(customizations, 'input_border_radius') ?? 'var(--border-radius-1)'
        };
        --button-border-radius: ${
          getBorderRadius(customizations, 'button_border_radius') ?? 'var(--border-radius-1)'
        };

        --error-text-color: ${customizations.error_text_color ?? '#fa4040'};
        --error-border-color: ${customizations.error_border_color ?? '#fa4040'} ;

        display: block;
        width: 100%;
      }

      p {
        margin: 0;
      }

      ${FORM_STYLES}
      ${FORM_HEADER_STYLES}
      ${UNREGISTERED_FORM_STYLES}
      ${FORM_FIELD_STYLES}
      ${FORM_INPUT_STYLES}
      ${FORM_SELECT_STYLES}
      ${FORM_BUTTON_STYLES}
      ${DISCLAIMER_STYLES}
      ${CALENDAR_LINK_STYLES}
      ${CALENDAR_LINKS_STYLES}
      ${NON_REGISTRATION_FORM_CONTENT_STYLES}
      ${POWERED_BY_WISTIA_BADGE_STYLES}
    `}
    </style>
  );
};
