import { h, JSX } from 'preact';
import { isNonEmptyString, Nullable } from '@wistia/type-guards';
import { useFormState } from '../../context/FormStateContext.tsx';
import { CalendarIcon } from '../Icons/CalendarIcon.tsx';
import { ClockIcon } from '../Icons/ClockIcon.tsx';
import { getLocalDateAndTime } from '../../utilities/eventHelpers.ts';

export const FORM_HEADER_STYLES = `
  .header {
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-2);
  }

  .title {
    font-size: var(--title-font-size);
    font-weight: var(--font-weight-1);
    font-family: var(--title-font-family);
    color: var(--form-heading-color);
  }

  .date-and-time {
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
    color: var(--form-text-color);
  }
`;

export const FormHeader = (): Nullable<JSX.Element> => {
  const {
    liveEventConfig: { customizations, scheduled_for },
  } = useFormState();
  const {
    title,
    date_enabled: dateEnabled,
    time_enabled: timeEnabled,
    title_enabled: titleEnabled,
  } = customizations;

  const shouldShowTitle = isNonEmptyString(title) && titleEnabled;

  const shouldShowHeader = dateEnabled || timeEnabled || shouldShowTitle;

  if (!shouldShowHeader) {
    return null;
  }

  const { date, time } = getLocalDateAndTime(scheduled_for);

  return (
    <div className="header" part="form-header">
      {shouldShowTitle && (
        <span class="title" part="event-title">
          {title}
        </span>
      )}
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: 'var(--spacing-4)',
        }}
      >
        {dateEnabled && (
          <span class="date-and-time" part="event-date">
            <CalendarIcon fill="var(--form-text-color)" />
            {date}
          </span>
        )}
        {timeEnabled && (
          <span class="date-and-time" part="event-time">
            <ClockIcon fill="var(--form-text-color)" />
            {time}
          </span>
        )}
      </div>
    </div>
  );
};
