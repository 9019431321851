import { h, JSX } from 'preact';

export const GoogleCalendarIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.9827 1.5H11.7881V4.69734H14.9994V2.40157C15.0003 2.40157 14.8407 1.58719 13.9827 1.5Z"
        fill="#1967D2"
      />
      <path
        d="M11.7886 15.0575V15.0654V15.0723L14.9999 11.875H14.9711L11.7886 15.0575Z"
        fill="#1967D2"
      />
      <path d="M15 11.8764V11.8477L14.9712 11.8764H15Z" fill="#FBBC05" />
      <path d="M14.9999 4.69531H11.7886V11.8451H14.9999V4.69531Z" fill="#FBBC05" />
      <path d="M14.9711 11.875H11.7886V15.0575L14.9711 11.875Z" fill="#EA4335" />
      <path d="M11.7886 11.8764H14.9711L14.9999 11.8477H11.7886V11.8764Z" fill="#EA4335" />
      <path d="M11.7808 15.0667H11.7889V15.0586L11.7808 15.0667Z" fill="#34A853" />
      <path d="M4.63867 11.8477V15.0668H11.7806L11.7884 11.8477H4.63867Z" fill="#34A853" />
      <path d="M11.7886 11.8764V11.8477L11.7808 15.0668L11.7886 15.059V11.8764Z" fill="#34A853" />
      <path
        d="M1.5 11.8477V14.0859C1.52877 14.8122 2.31002 15.0668 2.31002 15.0668H4.63893V11.8477H1.5Z"
        fill="#188038"
      />
      <path
        d="M4.63893 4.69735H11.7887V1.5H2.4007C2.4007 1.5 1.55755 1.58719 1.5 2.48789V11.8471H4.63893V4.69735Z"
        fill="#4285F4"
      />
      <path
        d="M6.97814 10.3976C6.81457 10.3976 6.65683 10.3762 6.50495 10.3334C6.35695 10.2905 6.22064 10.2263 6.09601 10.1406C5.97139 10.051 5.86039 9.94002 5.76302 9.80761C5.66955 9.67519 5.5975 9.52135 5.54688 9.3461L6.26543 9.05984C6.31606 9.25457 6.40174 9.40257 6.52247 9.50383C6.6432 9.60119 6.79509 9.64988 6.97814 9.64988C7.05992 9.64988 7.13782 9.63819 7.21181 9.61482C7.28581 9.58756 7.35007 9.55056 7.4046 9.50383C7.45912 9.45709 7.50196 9.40257 7.53312 9.34026C7.56817 9.27405 7.58569 9.20005 7.58569 9.11826C7.58569 8.9469 7.52143 8.81254 7.39291 8.71517C7.26829 8.61781 7.09498 8.56913 6.87298 8.56913H6.52831V7.87394H6.84377C6.92167 7.87394 6.99761 7.8642 7.07161 7.84473C7.14561 7.82526 7.20987 7.79605 7.26439 7.7571C7.32281 7.71426 7.3676 7.66169 7.39875 7.59937C7.43381 7.53316 7.45133 7.45722 7.45133 7.37154C7.45133 7.23912 7.4046 7.13202 7.31113 7.05023C7.21766 6.96455 7.09108 6.92171 6.9314 6.92171C6.76004 6.92171 6.62762 6.96845 6.53415 7.06192C6.44458 7.15149 6.38227 7.25275 6.34721 7.3657L5.64619 7.07944C5.68124 6.98208 5.73382 6.88277 5.80392 6.78151C5.87402 6.67635 5.96165 6.58288 6.0668 6.5011C6.17585 6.41542 6.30243 6.34726 6.44653 6.29663C6.59063 6.24211 6.75615 6.21484 6.94309 6.21484C7.13392 6.21484 7.30723 6.24211 7.46302 6.29663C7.62269 6.35115 7.759 6.4271 7.87195 6.52446C7.98489 6.61793 8.07252 6.73088 8.13483 6.86329C8.19715 6.99182 8.2283 7.13202 8.2283 7.28391C8.2283 7.40075 8.21272 7.5059 8.18157 7.59937C8.15431 7.69284 8.11731 7.77658 8.07057 7.85057C8.02384 7.92457 7.96931 7.98883 7.907 8.04336C7.84858 8.09399 7.78821 8.13488 7.7259 8.16604V8.21277C7.91284 8.28677 8.06668 8.40555 8.18741 8.56913C8.31204 8.7327 8.37435 8.93911 8.37435 9.18837C8.37435 9.36362 8.34125 9.52525 8.27504 9.67324C8.20883 9.81734 8.11341 9.94392 7.98879 10.053C7.86805 10.162 7.72201 10.2457 7.55064 10.3042C7.37928 10.3665 7.18845 10.3976 6.97814 10.3976Z"
        fill="#4285F4"
      />
      <path
        d="M9.76942 10.3042V7.20797L9.06255 7.5059L8.78214 6.85745L9.95636 6.30831H10.5347V10.3042H9.76942Z"
        fill="#4285F4"
      />
    </svg>
  );
};
