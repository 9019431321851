import { h, JSX } from 'preact';

export const AppleCalendarIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_43913_150302)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5501 7.40666C10.5338 5.76083 11.8924 4.97105 11.9531 4.93194C11.1894 3.8149 9.9997 3.66136 9.57578 3.64409C8.56334 3.54188 7.60055 4.24051 7.08618 4.24051C6.57322 4.24051 5.78055 3.65943 4.94109 3.6748C3.83772 3.69111 2.82047 4.31656 2.25166 5.30478C1.10532 7.29366 1.95845 10.2415 3.07576 11.8542C3.62155 12.644 4.27245 13.5312 5.12726 13.4995C5.95016 13.4671 6.26157 12.9664 7.25698 12.9664C8.25215 12.9664 8.53165 13.4995 9.4028 13.4825C10.2885 13.4662 10.8497 12.6773 11.3919 11.8849C12.0193 10.9684 12.2765 10.0822 12.2921 10.0366C12.2724 10.0277 10.5649 9.37372 10.5476 7.40737L10.55 7.40666H10.5501ZM8.91385 2.57549C9.36729 2.02537 9.67366 1.26172 9.58994 0.499512C8.93664 0.526141 8.14444 0.934951 7.67542 1.48436C7.25436 1.97211 6.88656 2.74943 6.98494 3.49603C7.71452 3.55291 8.45944 3.12537 8.91312 2.57644L8.91385 2.57549Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_43913_150302">
          <rect width="13" height="13" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
