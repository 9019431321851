import { h, JSX } from 'preact';
import { isEmptyString, isNil } from '@wistia/type-guards';
import { uniqId } from '../../../utilities/uniqId.js';
import { BaseFormFieldConfig } from '../types.ts';

export const DISCLAIMER_STYLES = `
  .disclaimer {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: var(--spacing-1);
    color: var(--form-text-color);
  }

  .disclaimer > label {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-4);
    padding: var(--spacing-2);
    align-items: center;
    font-family: var(--input-label-font-family);
  }

  .disclaimer label input {
    display: flex;
    align-items: center;
    accent-color: var(--button-background-color);
  }

  input[type='checkbox']:focus {
    outline: 2px solid var(--button-border-color);
  }

  .disclaimer-text {
    font-family: var(--input-label-font-family);
    display: flex;
    gap: var(--spacing-2);
    flex-direction: column;
  }
`;

export type DisclaimerFormFieldConfig = BaseFormFieldConfig & {
  additional_field_data?: string;
  input_type: 'checkbox';
};

export const Disclaimer = ({
  field,
  part,
}: {
  field: DisclaimerFormFieldConfig;
  part: string;
}): JSX.Element | null => {
  const disclaimerTextContainerId = uniqId('wistia-form-disclaimer-text');

  if (isNil(field.additional_field_data) || isEmptyString(field.additional_field_data)) {
    return null;
  }

  return (
    <div class="disclaimer" part={`${part}-text`}>
      <div
        id={disclaimerTextContainerId}
        class="disclaimer-text"
        dangerouslySetInnerHTML={{ __html: field.additional_field_data }}
      ></div>
      <label htmlFor={field.slug}>
        <input
          aria-describedby={disclaimerTextContainerId}
          aria-label={`${field.label}: I have read and agreed to this disclaimer.`}
          aria-required={field.required}
          id={field.slug}
          name={field.slug}
          required={field.required}
          type={field.input_type}
          part={`checkbox ${part}`}
        />
        <span>I have read and agreed to this disclaimer.</span>
      </label>
    </div>
  );
};
