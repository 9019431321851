import { h, JSX } from 'preact';
import { Nullable } from '@wistia/type-guards';
import { useFormState } from '../../context/FormStateContext.tsx';
import { RegisteredFormContent } from './RegisteredFormContent.tsx';
import { UnregisteredFormContent } from './UnregisteredFormContent.tsx';
import { EventClosedContent } from './EventClosedContent.tsx';
import { getRegistrationByEventId } from '../../utilities/localStorageHelpers.ts';
import { countMetric } from '../../../../utilities/simpleMetrics.js';
import { PoweredByWistiaBadge } from '../PoweredByWistiaBadge.tsx';

export const FORM_STYLES = `
  .form-container {
    background-color: var(--form-background-color);
    border-radius: var(--form-border-radius);
    box-sizing: border-box;
    container-name: form-container;
    container-type: inline-size;
    color: var(--form-text-color);
    font-family: var(--font-family);
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
  }

  .disclaimer a {
    color: inherit;
  }
`;

export const NON_REGISTRATION_FORM_CONTENT_STYLES = `
  .post-registration-content, .vod-disabled-content, .vod-not-ready-content, .vod-ready-content, .event-joinable-content {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-4);
    column-gap: var(--spacing-4);
    font-size: var(--font-size-3);
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100%;
    font-family: var(--title-font-family);

    @container form-container (max-height: 600px) {
      height: auto;
    };
  }

  .loud {
    font-size: 24px;
    line-height: 36px;
  }
`;

/*
 * A miniature router for the contents of the form, given whether or not the user is registered.
 *
 * Each component returned here is the form's contents, not the form itself.
 * */
const Router = (): Nullable<JSX.Element> => {
  const {
    liveEventId,
    isRegistered,
    liveEventConfig: { has_registration_form, on_demand_enabled, is_event_over, customizations },
    isPreview,
  } = useFormState();

  if (isPreview) {
    countMetric(`live_reg_form/customize_form_rendered`, 1, { liveEventId, customizations });
    return <UnregisteredFormContent />;
  }

  if (is_event_over && !on_demand_enabled) {
    countMetric('live_reg_form/event_over_rendered', 1, { liveEventId, customizations });
    return <EventClosedContent />;
  }

  if (isRegistered || !has_registration_form) {
    const { id: registrationUid } = getRegistrationByEventId(liveEventId) ?? {};
    countMetric('live_reg_form/registered_form_rendered', 1, {
      liveEventId,
      customizations,
      isRegistered,
      registrationUid,
    });
    return <RegisteredFormContent />;
  }

  countMetric('live_reg_form/unregistered_form_rendered', 1, { liveEventId, customizations });
  return <UnregisteredFormContent />;
};

export const FormStateRouter = (): Nullable<JSX.Element> => {
  const {
    isWistiaPage,
    liveEventConfig: { customizations },
  } = useFormState();
  return (
    <div className="form-container" part="form-container">
      <Router />
      {!isWistiaPage && customizations.has_powered_by_wistia_badge ? (
        <PoweredByWistiaBadge />
      ) : null}
    </div>
  );
};
