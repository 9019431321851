import { isEmptyRecord, isNil, isNotNil, Nilable } from '@wistia/type-guards';
import { LiveFormCustomizations } from './FormApi.ts';

const SM_FORM_MAX_WIDTH = 515;
const XS_FORM_MAX_WIDTH = 250;

export const BREAKPOINTS = {
  xs: XS_FORM_MAX_WIDTH,
  sm: SM_FORM_MAX_WIDTH,
};

export type Font = {
  fontFamily: string;
  url?: string;
};

export const getFontFamilyArrayFromData = (
  customizations: LiveFormCustomizations | null,
): Font[] => {
  if (isNil(customizations) || isEmptyRecord(customizations)) {
    return [];
  }

  const {
    input_font,
    input_font_url,
    input_label_font,
    input_label_font_url,
    button_font,
    button_font_url,
    title_font,
    title_font_url,
  } = customizations;

  const fonts: Font[] = [];

  if (isNotNil(input_font)) {
    fonts.push({ fontFamily: input_font, url: input_font_url });
  }

  if (isNotNil(input_label_font)) {
    fonts.push({ fontFamily: input_label_font, url: input_label_font_url });
  }

  if (isNotNil(button_font)) {
    fonts.push({ fontFamily: button_font, url: button_font_url });
  }

  if (isNotNil(title_font)) {
    fonts.push({ fontFamily: title_font, url: title_font_url });
  }

  return fonts;
};

export const appendUnit = (value: Nilable<number | string>, unit: string): Nilable<string> => {
  if (isNil(value)) {
    return null;
  }

  return `${value}${unit}`;
};

export const getBorderRadius = (
  customizations: LiveFormCustomizations | null,
  propertyIfNotSynced: keyof Pick<
    LiveFormCustomizations,
    'button_border_radius' | 'container_border_radius' | 'input_border_radius'
  >,
  unit = 'px',
): Nilable<string> => {
  if (isNil(customizations) || isEmptyRecord(customizations)) {
    return null;
  }

  const { border_radius } = customizations;

  return appendUnit(customizations[propertyIfNotSynced], unit) ?? appendUnit(border_radius, unit);
};
