import type { JSX } from 'preact';
import { h } from 'preact';
import { Nilable } from '@wistia/type-guards';
import { GoogleCalendarIcon } from './Icons/GoogleCalendarIcon.tsx';
import { AppleCalendarIcon } from './Icons/AppleCalendarIcon.tsx';
import { OutlookCalendarIcon } from './Icons/OutlookCalendarIcon.tsx';
import { CalendarLinkType } from './CalendarLinks.tsx';

type CalendarLinkProps = {
  calendarLink: Nilable<string>;
  calendarType: CalendarLinkType;
  onClick: (calendarType: CalendarLinkType) => void;
};

const getCalendarText = (calendarType: CalendarLinkType): string => {
  switch (calendarType) {
    case 'google':
      return 'Google Calendar';
    case 'ical':
      return 'Apple iCal';
    case 'outlook':
      return 'Microsoft Outlook';
    default:
      return 'Add to Calendar';
  }
};

const getCalendarIcon = (calendarType: CalendarLinkType): JSX.Element | null => {
  switch (calendarType) {
    case 'google':
      return <GoogleCalendarIcon />;
    case 'ical':
      return <AppleCalendarIcon />;
    case 'outlook':
      return <OutlookCalendarIcon />;
    default:
      return null;
  }
};

export const CALENDAR_LINK_STYLES = `
  .calendar-link {
    background: var(--grey-100);
    appearance: none;
    padding: var(--spacing-2);
    align-items: center;
    border: 1px solid transparent;
    cursor: pointer;
    color: var(--grey-900);
    border-radius: var(--border-radius-1);
    display: flex;
    justify-content: center;
    vertical-align: middle;
    text-decoration: none;
    outline: none;
    flex-grow: 1;
    gap: var(--spacing-2);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-family: var(--button-font-family);
  }

  .calendar-link:hover {
    background: var(--grey-200);
  }

  calendar-link:focus {
    box-shadow: inset 0 0 0 1px white, inset 0 0 0 2px var(--grey-900);
    border-color: var(--grey-900);
    outline: thin dotted #999;
  }

  .calendar-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spacing-4);
  }

  .calendar-text-container {
    flex-shrink: 1;
    font-size: var(--font-size-3);
    line-height: var(--spacing-6);
    text-align: center;
  }
`;

export const CalendarLink = ({
  calendarLink,
  calendarType,
  onClick,
}: CalendarLinkProps): JSX.Element | null => {
  return (
    <a
      class="calendar-link"
      part={`calendar-link calendar-link-${calendarType}`}
      href={calendarLink ?? ''}
      onClick={() => onClick(calendarType)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div class="calendar-icon-container">{getCalendarIcon(calendarType)}</div>
      <div class="calendar-text-container">{getCalendarText(calendarType)}</div>
    </a>
  );
};
