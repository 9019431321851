import { h, JSX } from 'preact';

export const OutlookCalendarIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_43913_150326)">
        <path
          d="M14.0001 7.3257C14.0009 7.21633 13.9443 7.11434 13.8511 7.05719H13.8494L13.8437 7.05391L8.99235 4.18227C8.97129 4.16805 8.94969 4.1552 8.92727 4.14344C8.73996 4.04691 8.51739 4.04691 8.33008 4.14344C8.30766 4.1552 8.28579 4.16805 8.265 4.18227L3.41368 7.05363L3.40793 7.05691C3.25973 7.14906 3.21434 7.34402 3.30649 7.49223C3.33356 7.53598 3.37102 7.57234 3.41559 7.59832L8.26664 10.47C8.2877 10.4839 8.3093 10.497 8.33172 10.5088C8.51903 10.6053 8.74161 10.6053 8.92891 10.5088C8.95133 10.497 8.97293 10.4842 8.99399 10.47L13.845 7.59832C13.9421 7.54199 14.0012 7.43781 14.0001 7.3257Z"
          fill="#0A2767"
        />
        <path
          d="M3.97936 5.40836H7.16299V8.32649H3.97936V5.40836ZM13.349 2.44184V1.10692C13.3566 0.773052 13.0925 0.496333 12.7586 0.488403H4.49725C4.16338 0.496333 3.89924 0.773325 3.9069 1.10692V2.44184L8.79049 3.74422L13.349 2.44184Z"
          fill="#0364B8"
        />
        <path d="M3.90674 2.44177H7.16256V5.37193H3.90674V2.44177Z" fill="#0078D4" />
        <path
          d="M10.4184 2.44177H7.1626V5.37193L10.4184 8.30208H13.3486V5.3722L10.4184 2.44177Z"
          fill="#28A8EA"
        />
        <path d="M7.1626 5.37219H10.4184V8.30235H7.1626V5.37219Z" fill="#0078D4" />
        <path d="M7.1626 8.30237H10.4184V11.2325H7.1626V8.30237Z" fill="#0364B8" />
        <path d="M3.97949 8.32642H7.16312V10.9793H3.97949V8.32642Z" fill="#14447D" />
        <path d="M10.4185 8.30237H13.3486V11.2325H10.4185V8.30237Z" fill="#0078D4" />
        <path
          d="M13.8507 7.58056L13.8445 7.58384L8.9934 10.3122C8.97234 10.3253 8.95074 10.3376 8.92832 10.3486C8.84601 10.3877 8.75633 10.4101 8.66527 10.4136L8.40031 10.2586C8.37789 10.2474 8.35629 10.2348 8.33523 10.2211L3.41883 7.4154H3.41664L3.25586 7.32544V12.8489C3.25832 13.2175 3.5591 13.5142 3.92742 13.5117H13.3386C13.3441 13.5117 13.349 13.509 13.355 13.509C13.4329 13.504 13.5095 13.4879 13.5828 13.4614C13.6145 13.448 13.6451 13.4321 13.6744 13.4138C13.6962 13.4015 13.7337 13.3744 13.7337 13.3744C13.9005 13.2511 13.9992 13.0561 14 12.8486V7.32571C13.9998 7.43126 13.9426 7.5286 13.8507 7.58056Z"
          fill="url(#paint0_linear_43913_150326)"
        />
        <path
          opacity="0.5"
          d="M13.7394 7.30376V7.64228L8.66689 11.1349L3.41525 7.41778C3.41525 7.41587 3.41389 7.4145 3.41197 7.4145L2.93018 7.12466V6.88048L3.12869 6.8772L3.54869 7.1181L3.55854 7.12138L3.59436 7.14407C3.59436 7.14407 8.53018 9.96048 8.5433 9.96677L8.73225 10.0775C8.74865 10.0709 8.76479 10.0644 8.78447 10.0581C8.79432 10.0515 13.6845 7.30048 13.6845 7.30048L13.7394 7.30376Z"
          fill="#0A2767"
        />
        <path
          d="M13.8507 7.58053L13.8445 7.58408L8.9934 10.3124C8.97234 10.3256 8.95074 10.3379 8.92832 10.3488C8.73992 10.441 8.51953 10.441 8.33113 10.3488C8.30871 10.3379 8.28711 10.3256 8.26605 10.3124L3.415 7.58408L3.40926 7.58053C3.31547 7.52967 3.25695 7.43205 3.25586 7.32568V12.8488C3.25832 13.2172 3.55883 13.5141 3.92715 13.5117H13.3287C13.6971 13.5141 13.9979 13.2172 14 12.8488V7.32568C13.9998 7.43123 13.9426 7.52857 13.8507 7.58053Z"
          fill="#1490DF"
        />
        <path
          opacity="0.1"
          d="M9.06416 10.2722L8.99143 10.313C8.97037 10.3264 8.94877 10.3387 8.92635 10.3499C8.84623 10.3892 8.75928 10.4122 8.67041 10.4177L10.5161 12.6003L13.7358 13.376C13.8242 13.3093 13.8942 13.2218 13.9401 13.1212L9.06416 10.2722Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M9.39332 10.0872L8.99191 10.3127C8.97086 10.3261 8.94926 10.3384 8.92684 10.3497C8.84672 10.389 8.75977 10.412 8.6709 10.4175L9.53551 12.8016L13.7371 13.375C13.9026 13.2508 14.0002 13.0559 14.0002 12.8489V12.7775L9.39332 10.0872Z"
          fill="black"
        />
        <path
          d="M3.93645 13.5117H13.3279C13.4726 13.5125 13.6134 13.4668 13.7299 13.3815L8.40004 10.2594C8.37762 10.2482 8.35602 10.2356 8.33496 10.2219L3.41855 7.41619H3.41637L3.25586 7.32568V12.83C3.25559 13.206 3.5602 13.5114 3.93645 13.5117Z"
          fill="#28A8EA"
        />
        <path
          opacity="0.1"
          d="M7.81406 3.68978V10.6345C7.81352 10.8779 7.66531 11.0969 7.43973 11.188C7.36973 11.2181 7.29453 11.2336 7.21824 11.2336H3.25586V3.4188H3.90691V3.09314H7.21797C7.54719 3.09423 7.81324 3.36083 7.81406 3.68978Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M7.4884 4.01539V10.9602C7.48922 11.0389 7.47254 11.1166 7.43945 11.1879C7.34895 11.4108 7.13293 11.5571 6.89258 11.5582H3.25586V3.41848H6.89258C6.98691 3.41766 7.08016 3.44117 7.16273 3.48684C7.36234 3.58746 7.4884 3.79199 7.4884 4.01539Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M7.4884 4.01539V10.3088C7.48676 10.6375 7.22125 10.9041 6.89258 10.9068H3.25586V3.41848H6.89258C6.98691 3.41766 7.08016 3.44117 7.16273 3.48684C7.36234 3.58746 7.4884 3.79199 7.4884 4.01539Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M7.16273 4.01537V10.3088C7.16246 10.638 6.89613 10.9052 6.56691 10.9068H3.25586V3.41846H6.56691C6.89613 3.41873 7.16301 3.68561 7.16273 4.01482C7.16273 4.0151 7.16273 4.0151 7.16273 4.01537Z"
          fill="black"
        />
        <path
          d="M0.596914 3.41846H6.56605C6.89555 3.41846 7.16297 3.68561 7.16297 4.01537V9.98451C7.16297 10.314 6.89582 10.5814 6.56605 10.5814H0.596914C0.267148 10.5814 0 10.314 0 9.98451V4.01537C0 3.68588 0.267148 3.41846 0.596914 3.41846Z"
          fill="url(#paint1_linear_43913_150326)"
        />
        <path
          d="M1.86555 5.92154C2.01266 5.60819 2.25001 5.34596 2.54723 5.1685C2.87645 4.9801 3.25133 4.88604 3.63032 4.89697C3.98168 4.88932 4.3284 4.97846 4.63274 5.15455C4.91876 5.32518 5.14926 5.5751 5.29583 5.87397C5.45579 6.20346 5.53536 6.56604 5.52825 6.93217C5.5359 7.31471 5.45415 7.69397 5.28899 8.03904C5.13887 8.34885 4.90098 8.60752 4.6054 8.78362C4.2893 8.96518 3.92973 9.05651 3.56551 9.04803C3.20649 9.05678 2.85211 8.96654 2.54094 8.78744C2.25247 8.61654 2.01922 8.36635 1.86883 8.06666C1.70805 7.74209 1.62739 7.38334 1.63368 7.02131C1.62711 6.64205 1.70641 6.26608 1.86555 5.92154ZM2.59208 7.68959C2.67055 7.88783 2.80344 8.05955 2.97571 8.18506C3.15098 8.30756 3.36098 8.37072 3.57481 8.36553C3.80258 8.37455 4.02708 8.3092 4.21438 8.17932C4.38446 8.05408 4.51379 7.88182 4.5868 7.68385C4.66856 7.46264 4.70876 7.2283 4.70575 6.9926C4.70821 6.75471 4.67047 6.51791 4.59364 6.2926C4.52583 6.08916 4.40059 5.90979 4.23297 5.77635C4.05032 5.64045 3.82665 5.57127 3.59915 5.58111C3.38067 5.57537 3.16602 5.63908 2.9861 5.76322C2.8111 5.88928 2.67547 6.06264 2.59536 6.26307C2.41762 6.72217 2.41653 7.23104 2.59262 7.69069L2.59208 7.68959Z"
          fill="white"
        />
        <path d="M10.4185 2.44177H13.3486V5.37193H10.4185V2.44177Z" fill="#50D9FF" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_43913_150326"
          x1="8.62766"
          y1="7.32563"
          x2="8.62766"
          y2="13.5117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35B8F1" />
          <stop offset="1" stopColor="#28A8EA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_43913_150326"
          x1="1.24432"
          y1="2.95223"
          x2="5.91847"
          y2="11.0477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1784D9" />
          <stop offset="0.5" stopColor="#107AD5" />
          <stop offset="1" stopColor="#0A63C9" />
        </linearGradient>
        <clipPath id="clip0_43913_150326">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
