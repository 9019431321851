import { h, JSX } from 'preact';

export const EventClosedContent = (): JSX.Element => {
  return (
    <div className="vod-disabled-content" part="vod-disabled-content non-form-content">
      <p className="loud">The event is closed.</p>
      <p>
        Sorry, this event has ended. If you have any questions, please contact the event
        coordinator.
      </p>
    </div>
  );
};
