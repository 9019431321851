import { h, Fragment, JSX } from 'preact';
import { isNil, Nullable } from '@wistia/type-guards';
import { FormInput } from './FormInput.tsx';
import { BaseFormFieldConfig } from '../types.ts';
import { FormSelect, SelectFormFieldConfig } from './FormSelect.tsx';
import { useFormValidation } from '../context/FormValidationContext.tsx';
import { Disclaimer, DisclaimerFormFieldConfig } from './Disclaimer.tsx';
import { BREAKPOINTS } from '../utilities/styleHelpers.ts';
import { FormValidation } from '../utilities/validateFormFields.ts';
import { FieldValidationOptions } from '../validation/types.ts';

export const FORM_FIELD_STYLES = `
  .form-field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: var(--spacing-2);
    min-width: 250px;
  }

  .field-first_name, .field-last_name {
    min-width: 0;
  }

  .field-disclaimer {
    flex-basis: 100%;
  }

  .form-field > label {
    font-weight: var(--font-weight-2);
    display: flex;
    gap: var(--spacing-1);
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    font-family: var(--input-label-font-family);
    color: var(--form-text-color);
  }

  .requiredness-indicator {
    color: var(--input-requiredness-text-color);
    font-weight: var(--font-weight-1);
  }

  .validation-error {
    border-left: 4px solid var(--error-border-color);
    color: var(--error-text-color);
    padding-left: var(--spacing-2);
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-2);
    line-height: var(--spacing-4);
    margin: 0;
  }

  @container form-container (max-width: ${BREAKPOINTS.xs}px) {
    .form-field {
      min-width: 0;
    }
  }
`;

export type EmailFormFieldConfig = BaseFormFieldConfig & {
  input_type: 'email';
  placeholder: string;
};

export type TextFormFieldConfig = BaseFormFieldConfig & {
  input_type: 'text';
  placeholder: string;
};

export type TelFormFieldConfig = BaseFormFieldConfig & {
  input_type: 'tel';
  placeholder: string;
};

export type FormFieldConfig =
  | DisclaimerFormFieldConfig
  | EmailFormFieldConfig
  | SelectFormFieldConfig
  | TelFormFieldConfig
  | TextFormFieldConfig;

export type CustomField = Pick<
  BaseFormFieldConfig,
  'enabled' | 'id' | 'input_type' | 'label' | 'placeholder' | 'required'
> & {
  custom: true;
  uuid: string;
  validation?: FieldValidationOptions;
};

export type Field = CustomField | FormFieldConfig;
export type FieldWithId = Field & { id: string };

const labelToValidClassName = (label: string): string =>
  label.trim().replace(/ /g, '_').replace(/-/g, '_').toLowerCase();

const getFieldComponent = (
  field: Field & { options?: string[]; slug?: string },
): JSX.Element | null => {
  if (isNil(field.slug) || isNil(field.input_type)) {
    return null;
  }

  switch (field.input_type) {
    case 'select':
      if (isNil(field.options)) {
        return null;
      }
      return (
        <FormSelect
          aria-label={field.label}
          aria-required={field.required}
          id={field.slug}
          part={field.slug}
          name={field.slug}
          required={field.required}
          type={field.input_type}
          options={field.options}
        />
      );
    case 'checkbox':
      return <Disclaimer field={field as DisclaimerFormFieldConfig} part={field.slug} />;
    case 'text':
    case 'email':
    case 'tel':
    default:
      return (
        <FormInput
          id={field.slug}
          part={field.slug}
          name={field.slug}
          placeholder={field.placeholder}
          required={field.required}
          aria-label={field.label}
          aria-required={field.required}
          type={field.input_type}
        />
      );
  }
};

const isTrueOrNull = (value: boolean | null): boolean => isNil(value) || value;

const getValidationError = (
  field: Field,
  validationResult: Nullable<FormValidation>,
): JSX.Element | null => {
  const validation = validationResult?.results.find(({ id }) =>
    field.custom ? id === field.id : id === field.slug,
  );

  if (
    isNil(validationResult) ||
    validationResult.isValid ||
    isNil(validation) ||
    isTrueOrNull(validation.isValid)
  ) {
    return null;
  }

  return (
    <Fragment>
      {validation.messages.map((message) => (
        <p key={message} class="validation-error" part="validation-error">
          {message}
        </p>
      ))}
    </Fragment>
  );
};

// TODO: Replace slugged fields with newer implementation
export const SluggedFormField = ({ field }: { field: Field & { slug?: string } }): JSX.Element => {
  const validationResult = useFormValidation();

  return (
    <div class={`form-field field-${field.slug}`} part={`field field-${field.slug}`}>
      <label htmlFor={field.slug} part="label">
        {field.label}
        {field.required && field.slug !== 'disclaimer' ? (
          <span part="requiredness-indicator" class="requiredness-indicator">
            {' '}
            (Required)
          </span>
        ) : null}
      </label>
      {getValidationError(field, validationResult)}
      {getFieldComponent(field)}
    </div>
  );
};

export const TextFormField = ({ field, id }: { field: Field; id: string }): JSX.Element => {
  const validationResult = useFormValidation();

  if (isNil(field.label)) {
    return <span />;
  }

  // Handle spaces in the label
  const className = labelToValidClassName(field.label);

  return (
    <div class={`form-field field-${className}`} part={`field field-${className}`}>
      <label htmlFor={id} part="label">
        {field.label}
        {field.required ? (
          <span part="requiredness-indicator" class="requiredness-indicator">
            {' '}
            (Required)
          </span>
        ) : null}
      </label>
      {getValidationError(field, validationResult)}
      <FormInput
        id={id}
        part={className}
        name={id}
        placeholder={field.placeholder}
        required={field.required}
        aria-label={field.label}
        aria-required={field.required}
        type={field.input_type}
      />
    </div>
  );
};
